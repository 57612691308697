body,
html,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fancy-spinner {
  width: 115px;
  height: 116px;
  border-radius: 50%;
  border: 0px solid transparent;
  border-top: 7px double #0dcaf0;
  animation: spin 1s linear infinite;
}

.fancy-spinner-container {
  background: #000000a9;
  z-index: 3;
  width: 100vw;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cs-message-list__scroll-wrapper {
  display: flex !important;
  flex-direction: column-reverse !important;
}

div.cs-conversation__unread-dot {
  position: absolute;
  left: .7rem;
}

div.cs-conversation {
  padding-left: 2rem;
}

ul>li:hover img.delete_img {
  display: block;
}

img.delete_img {
  display: none;
  position: absolute;
  top: 1.1rem;
  right: 1px;
  cursor: pointer;
  width: 1rem;
  height: 20px;
  animation: fadeIn .5s;
}

img.delete_img:hover {
  transform: scale(1.3);
}

.cs-conversation_time {
  position: absolute;
  top: 13px;
  right: 1rem;
  font-size: 14px;
  color: #243953b5;
  width: 110px;
  overflow: hidden;
  height: 20px;
}

.cs-main-container>.cs-sidebar.cs-sidebar--left {
  max-width: 350px;
}

/* 
.cs-conversation__unread-dot + .cs-conversation__content .cs-conversation__name {
  font-weight: 700!important;
} */

.cs-conversation:has(> .cs-conversation__unread-dot) .cs-conversation__name {
  font-weight: 700 !important;
}

@animation-keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(25px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(25px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.hidden { display: none; }

.absolute { position: absolute; }

.right-sidebar {
  right: 0;
  width: 25%;
  height: 100%;
  top: 0;
  background: #999;
  z-index: 10;
  animation: slideInRight 0.5s ease-out;
}

@keyframes slideInRight {
  from {right: -25%;}
  to {right: 0%;}
}