/* custom-styles.scss */

@import "./node_modules/@chatscope/chat-ui-kit-styles/themes/default/variables";

.cs-conversation {
    display: flex;
    flex-direction: column;
    padding: 0.75em;
    cursor: pointer;
    align-items: flex-start;
    background-color: #fff;
    border-bottom: 1px solid #ddd; /* Optional: add a bottom border for separation */
    position: relative;
}

.cs-conversation:hover,
.cs-conversation--active {
    background-color: #f3f8fc;
}

.cs-conversation__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
}

.cs-conversation__name {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
}

.cs-conversation__name--number {
    font-size: 1rem; /* Adjust if necessary */
}

.cs-conversation__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.cs-conversation__number {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.9em;
}

.cs-conversation__time {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.8em;
}

.cs-conversation__time--unread {
    color: #007bff; /* Match the blue color of the dot */
}

.cs-conversation__message {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.85em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cs-conversation__message--unread,
.cs-conversation__number--unread {
    font-weight: bold;
}

.cs-conversation__unread-dot {
    width: 10px;
    height: 10px;
    background-color: #007bff; /* Blue color */
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cs-conversation__actions {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0.5em;
    right: 0.5em;
}

img.delete_img {
    display: none;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
}

.cs-conversation:hover img.delete_img {
    display: block;
}
/* Define the style for the copied message */
.copied-message {
    position: fixed; /* Position relative to the viewport */
    bottom: 20px; /* Position 20px from the bottom of the page */
    left: 100%; /* Center horizontally */
    transform: translateX(-50%); /* Center the element horizontally */
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1000; /* Ensure it is on top of other elements */
    opacity: 0;
    transition: opacity 0.5s ease;
    pointer-events: none;
}

.copied-message.show {
    opacity: 1;
}